<template>
  <v-progress-linear
    :value="percent"
    :color="colorBar"
    :height="heightBar"
    :background-color="colorBkgd"
    class="border"
    disabled="true"
  >
      <strong class="ntitlesdark--text fs-xx font-weight-bold">{{ Math.ceil(percent) }}% {{ Math.ceil(percent) >= 10 && 90 > percent  ? title : ''}}</strong>
  </v-progress-linear>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    percent: {
      type: Number,
      default: 30
    },
    colorBar: {
      type: String,
      default: "error"
    },
    title: {
      type: String,
      default: "Cargando"
    },
    colorBkgd: {
      type: String,
      default: "white"
    },
    heightBar: {
      type: String,
      default: "50"
    },
  }
}
</script>

<style scoped>

</style>
