<template>
  <v-sheet
      min-height="3em"
      :color="colorBkgd"
      class="py-md-3 text-center"
  >
    <span class="fs-1_2 primary--text"> <b>{{ title }} </b> {{ description }}</span>
  </v-sheet>
</template>

<script>
export default {
  name: "PopupInformative",
  props: {
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    colorBkgd: {
      type: String,
      default: ""
    }
  }
}
</script>

<style scoped>

</style>
