<template>
  <v-col cols="2">
    <strong class="ntitlesdark--text fs-1_2">{{ titleInput }}</strong>
  </v-col>
</template>

<script>
export default {
  name: "TitleInput",
  props: {
    titleInput: {
      type: String,
      default: ""
    }
  }
}
</script>

<style scoped>

</style>
