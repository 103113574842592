<template>
  <v-sheet color="white" grid-list-xs class="pa-0 ma-0" fluid>
    <v-container>
      <v-row class="pa-0 ma-0">
        <v-col md="12" sm="12" class="pa-0 ma-0">
          <v-data-table
              :itemsPerPage="countItemsPerPage"
              :headers="headersTable"
              :items="dataTable"
              :item-class="rowDataTableClassValue"
              class="elevation-1 mytable"
              :hide-default-footer=" dataTable.length < countItemsPerPage "
              :expanded.sync="expanded"
              :single-expand="singleExpand"
              item-key="idExpand"
          >
            <template v-for="objHeader in headersTable" v-slot:[`item.${objHeader.value}`]="{ item }">
              <div :key="objHeader.value">
                <v-chip
                    v-if="item[objHeader.value] !== undefined ? item[objHeader.value].toString().indexOf(errorMessage) !== -1 : false"
                    large
                    label
                    :color="'error--text'"
                >
                  {{ item[objHeader.value] }}
                </v-chip>
                <template v-else>
                  <span> {{ item[objHeader.value] }}</span>
                </template>

              </div>
            </template>
            <template v-slot:item.Estado="{ item }">
              <v-chip
                  @click="onExpandRow(item)"
                  :scope="item"
                  large
                  label
                  :color="getColor(item.Estado)"
                  dark
              >
                {{ item.Estado }}
              </v-chip>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :key="item.aData" class="pa-13" style="text-align: end" :colspan="headers.length">
                <div class="column mx-10">
                  <template v-for="itemData in item.aData" class="col-12 my-2">
                    <span v-bind:key="itemData"><b>Mensaje</b>  <small> {{ JSON.stringify(itemData) }} <br/> </small></span>
                  </template>
                  <br/>
                  <template v-for="itemLog in item.aHistory" class="col-12 my-2">
                    <span v-bind:key="itemLog"><b>Log</b>  <small> {{ JSON.stringify(itemLog) }} <br/> </small></span>
                  </template>
                </div>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>

import TableEditImport from "./TableEditImport";

export default {
  name: "TablePreviewImport",
  mixins: [TableEditImport],
  props: {
    headersTable: {
      type: Array,
      default: new Array(),
    },
    dataTable: {
      type: Array,
      default: new Array(),
    },
  },

  data() {
    return {
      expanded: [],
      singleExpand: true,
      countItemsPerPage: 10,
      errorMessage: this.$store.state.errorMessagesImport
    };
  },
  methods: {
    onExpandRow(item) {
      if( item  !== this.expanded[0] && (item.aData || item.aHistory)) {
        this.expanded=[item];
      } else {
        this.expanded=[];
      }
    }
  }
}
</script>

<style scoped>
</style>
