<template>
  <v-col cols="4" class="pa-0">
    <v-select
        :value="processImport"
        class="chooser-processes"
        background-color="white"
        color="backgrey"
        :items="processesImportNames"
        :placeholder="placeholderInput"
        outlined
        @change="onChangeInput"
        hide-selected
    >
      <template slot="item" slot-scope="data">
        <h4 class="titleslight--text " v-if="!Boolean(data.item.length)">Deseleccionar</h4>
        <span v-else-if="processImport  !== data.item" class="third--text fs-1_3 ">{{ data.item }}</span>
      </template>
      <template slot="selection" slot-scope="data">
        <h4 class="titleslight--text " v-if="!Boolean(data.item.length)">{{ placeholderInput }}</h4>
        <strong class="third--text fs-1_3">{{ data.item }}</strong>
      </template>
    </v-select>
  </v-col>
</template>

<script>
export default {
  name: "SelectInput",
  props: {
    processesImportNames: {
      type: Array,
      default: () => ([])
    },
    processImport: {
      type: String,
      default: ""
    },
    placeholderInput: {
      type: String,
      default: ""
    },
    onChangeInput: {
      type: Function,
      default: ()=>{}
    }
  }
}
</script>

<style scoped>

</style>
