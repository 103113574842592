<template>
  <v-col class="flex-grow-0">
    <v-file-input
        @click="onInputClick"
        :ref="id"
        :accept="accept"
        :id="id"
        chips
        small-chips
        truncate-length="15"
        class="d-none"
        @change="onChangeInputFile"
    ></v-file-input>
    <label :for="id">
      <v-btn
          :for="id"
          color="buttondark"
          class="px-md-16 py-md-6 text-capitalize"
          elevation="10"
      >
        <label :for="id" class="fs-1_3">
          <strong class="white--text"  :for="id">{{ title }}</strong>
        </label>
      </v-btn>
    </label>
  </v-col>
</template>

<script>
export default {
  name: "BtnInputFile",
  methods: {
    onInputClick: (event) => {
      event.target.value = ''
    },
    onChangeInputFile: async function () {
      this.parsedFile = await this.$papa.parse(event.target.files[0], this.config);
    },
    completeImportFile: async function (data, file) {
      this.onDataLoad(data, file);
    }
  },
  data() {
    return {
      config: {
        delimiter: "",	// auto-detect
        newline: "",	// auto-detect
        quoteChar: '"',
        escapeChar: '"',
        header: true,
        transformHeader: undefined,
        dynamicTyping: true,
        preview: 0,
        encoding: "",
        worker: false,
        comments: false,
        step: undefined,
        complete: this.completeImportFile,
        error: ()=>{
          this.$swal({
            timer: 3000,
            icon:`error`,
            title:`Importación con error'`,
            showConfirmButton: false
          });
        },
        download: false,
        downloadRequestHeaders: undefined,
        downloadRequestBody: undefined,
        skipEmptyLines: true,
        chunk: undefined,
        chunkSize: undefined,
        fastMode: undefined,
        beforeFirstChunk: undefined,
        withCredentials: undefined,
        transform: undefined,
        delimitersToGuess: [',', '\t', '|', ';']
      }
    }
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    accept: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: ""
    },
    onDataLoad: {
      type: Function,
      default: () => {}
    }
  }

}
</script>

<style scoped>

</style>
