<template>
  <v-row class="white" >
    <v-col md="12" sm="12">
      <h1 class="text-center"><strong>{{ title }}</strong></h1>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Title",
  props: {
    title: {
      type: String,
      default: ""
    }
  }
}
</script>

<style scoped>

</style>
