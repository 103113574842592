<template>
  <v-col v-if="visible">
    <a @click="this.exportExcel" class="fs-1_3 titlesdark--text ">{{ title }}</a>
  </v-col>
</template>
<script>

import axiosServices from "@/services/axiosServices";

export default {
  name: "BtnDownloadXls",
  components: {
  },
  methods: {
    exportExcel: async function () {
      let response = [];
      //let error = false;
      let params = `openWindow=true&process=${this.nameFile}`;
      if(this.headImport !== "")  {
        params = params+`&headValue=`+this.headImport;
      }
      try {
        await axiosServices.getConfiguration('download/base', false, params).then((file) => {
          response = file.aData;
        });
      } catch (e) {
        //error = e;
        //console.error(error);
      }
      return response;
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    nameFile: {
      type: String,
      default: ""
    },
    headImport: {
      type: String,
      default: ""
    },
    fields: {
      type: Object,
      default: () => ([])
    },
    data: {
      type: Array,
      default: () => ([])
    },
    refComponent: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dataToExport: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],

    }
  },

}
</script>

<style scoped>

</style>
