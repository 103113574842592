<template>
  <v-sheet color="white" grid-list-xs class="pa-0 ma-0" fluid>
    <v-container>
      <v-row class="pa-0 ma-0">
        <v-col md="12" sm="12" class="pa-0 ma-0">
          <vue-excel-editor :cell-style="cellStyle" width="100%" ref="grid" v-model="dataTable" :validate="validWholeRecord" >
            <div v-for="itemHeader in headersTable" :key="itemHeader.id">
              <div v-if="itemHeader.value === 'Estado'">
                <vue-excel-column
                    :width="headersTable.length*2+'em'"
                    :readonly="true"
                    :type="itemHeader.TypeEditor"
                    :field="itemHeader.text"
                    :label="itemHeader.text" >
                </vue-excel-column>
              </div>
              <vue-excel-column  v-else
                 :width="headersTable.length*2+'em'"
                 :options="itemHeader.options"
                 :type="itemHeader.TypeEditor"
                 :field="itemHeader.text"
                 :label="itemHeader.text"
                 :validate="validFieldColumn"
                 :mandatory="itemHeader.IsRequired"
              >
              </vue-excel-column>
            </div>
          </vue-excel-editor>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>

import Ajv from "ajv";
export default {
  name: "TableEditImport",
  props: {
    headersTable: {
      type: Array,
    },
    dataTable: {
      type: Array,
    },
    schemaProcess: {
      type: Object
    },
  },
  mounted() {

  },
  data() {
    return {
      countItemsPerPage: 10
    };
  },
  methods: {
    getColorEstado (accion) {
      let baseStyle = {"color":'#a9805c', "background-color":'#fbbd88', "text-align": 'center'};
      if (accion === 'Error') {
        baseStyle.color = '#796463';
        baseStyle["background-color"] = '#F0C5C3';
      }
      if (accion === 'Completado') {
        baseStyle.color = '#2D392E';
        baseStyle["background-color"] = '#CFE4D4';
      }
      if (accion === 'Insertado') {
        baseStyle.color = '#9c863a';
        baseStyle["background-color"] = '#FFDD67';
      }
      return baseStyle;
    },
    validWholeRecord (content, oldContent, record) {
      if(record) {
        const ajv = new Ajv({coerceTypes:true}); // options can be passed, e.g. {allErrors: true}
        const validate = ajv.compile(this.schemaProcess)
        const valid = validate(record)
        if (!valid) {
          record['Estado'] = "Error";
        }
        else {
          record['Estado'] = "Insertado";
        }
      }
      return '' // return empty string if there is no error
    },
    validFieldColumn (newValue, lastValue, values, field) {
      if (newValue === '' && field.mandatory) return 'Campo Vacio';
      if (newValue.indexOf(this.$store.state.errorMessagesImport) !== -1) return 'Error en el campo';
      return '' // return empty string if there is no error
    },
    cellStyleValidate() {
      return false;
    },
    cellStyle(data, c) {
      if(c.name === 'Estado') {
        return this.getColorEstado(data[c.name]);
      }
      if (data[c.name] !== undefined && data[c.name].toString().indexOf(this.$store.state.errorMessagesImport) !== -1) {
        return {"color":"#DAAAAA"};
      }
      return {"color":"#000"};
    },
    rowDataTableClassValue() {
      return "black--text fs-1_3 itemtd"
    },
    getColor (accion) {
      if (accion === 'Error') return 'st_disable st_t_disable--text'
      else if (accion === 'Insertado') return 'st_enable st_t_enable--text'
      else if (accion === 'Completado') return 'st_success st_t_success--text'
      else return 'st_default st_t_default--text'
    },
  }
}
</script>

<style scoped>
</style>
