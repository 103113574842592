<template>
  <v-col class="my-auto">
    <span class="fs-1_3 third--text">
      {{title}}
    </span>
  </v-col>
</template>

<script>
export default {
  name: "TextInformative",
  props: {
    title: {
      type: String,
      default: ""
    }
  }
}
</script>

<style scoped>

</style>
