<template>
  <v-breadcrumbs :items="historyPages">
    <template v-slot:divider>
      <v-icon color="titlesdark">&gt;</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
        <span :class="breadcrumbClassValue(item.disabled)">{{ item.text }}</span>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "BreadCrumb",
  props: {
    historyPages: {
      type: Array,
      default: () => ([])
    },
  },
  methods: {
    breadcrumbClassValue: function (state) {
      var value = ['title ntitlesdark--text']
      if (state) {
        value.push('font-weight-bold')
      }
      return value.join(' ')
    },
  }
}
</script>

<style scoped>

</style>
