var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-0 ma-0",attrs:{"color":"white","grid-list-xs":"","fluid":""}},[_c('v-container',[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"md":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1 mytable",attrs:{"itemsPerPage":_vm.countItemsPerPage,"headers":_vm.headersTable,"items":_vm.dataTable,"item-class":_vm.rowDataTableClassValue,"hide-default-footer":_vm.dataTable.length < _vm.countItemsPerPage,"expanded":_vm.expanded,"single-expand":_vm.singleExpand,"item-key":"idExpand"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([_vm._l((_vm.headersTable),function(objHeader){return {key:("item." + (objHeader.value)),fn:function(ref){
var item = ref.item;
return [_c('div',{key:objHeader.value},[(item[objHeader.value] !== undefined ? item[objHeader.value].toString().indexOf(_vm.errorMessage) !== -1 : false)?_c('v-chip',{attrs:{"large":"","label":"","color":'error--text'}},[_vm._v(" "+_vm._s(item[objHeader.value])+" ")]):[_c('span',[_vm._v(" "+_vm._s(item[objHeader.value]))])]],2)]}}}),{key:"item.Estado",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"scope":item,"large":"","label":"","color":_vm.getColor(item.Estado),"dark":""},on:{"click":function($event){return _vm.onExpandRow(item)}}},[_vm._v(" "+_vm._s(item.Estado)+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{key:item.aData,staticClass:"pa-13",staticStyle:{"text-align":"end"},attrs:{"colspan":headers.length}},[_c('div',{staticClass:"column mx-10"},[_vm._l((item.aData),function(itemData){return [_c('span',{key:itemData},[_c('b',[_vm._v("Mensaje")]),_vm._v(" "),_c('small',[_vm._v(" "+_vm._s(JSON.stringify(itemData))+" "),_c('br')])])]}),_c('br'),_vm._l((item.aHistory),function(itemLog){return [_c('span',{key:itemLog},[_c('b',[_vm._v("Log")]),_vm._v(" "),_c('small',[_vm._v(" "+_vm._s(JSON.stringify(itemLog))+" "),_c('br')])])]})],2)])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }