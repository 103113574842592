<template>
  <v-col v-if="visible">
    <a @click="this.exportExcel" class="fs-1_3 titlesdark--text ">{{ title }}</a>
  </v-col>
</template>
<script>


import XLSX from "xlsx";

export default {
  name: "BtnDownloadLocalXls",
  components: {
  },
  methods: {
    exportExcel: async function () {
      let dataJson = [];
      let dataHeaders = [];
      const workbook = XLSX.utils.book_new()
      if (this.$store.state.selectedProcessImport.fields)
        await this.$store.state.selectedProcessImport.fields.Headers.forEach((field) => {
          dataHeaders.push(`${field.Label}${field.IsRequired ? " *": ""}`);
        });
      dataJson.push(dataHeaders);
      await this.dataErrors.forEach((itemDataErrorIn) => {
        let itemDataError = itemDataErrorIn;
        delete itemDataError.Estado;
        delete itemDataError.nIdUser;
        dataJson.push(Object.values(itemDataError));
      });
      const filename = this.nameFile;
      var worksheet = XLSX.utils.aoa_to_sheet(dataJson);
      XLSX.utils.book_append_sheet(workbook, worksheet, filename)
      XLSX.writeFile(workbook, `Errores-${filename}-${new Date()/1000}.xlsx`)
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    nameFile: {
      type: String,
      default: ""
    },
    fields: {
      type: Object,
      default: () => ([])
    },
    data: {
      type: Array,
      default: () => ([])
    },
    dataErrors: {
      type: Array,
      default: () => ([])
    },
    refComponent: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dataToExport: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],

    }
  },

}
</script>

<style scoped>

</style>
