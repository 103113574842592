<template>
  <v-btn
      :depressed="depressedBtn"
      :outlined="outlinedBtn"
      :color="colorBtn"
      :class="classBtn"
      :light="lightBtn"
      @click="callbackBtn"
  >
    <strong :class="classText">
      {{ title }}
    </strong>
  </v-btn>
</template>

<script>
export default {
  name: "Btn",
  props: {
    title: {
      type: String,
      default: ""
    },
    classBtn: {
      type: String,
      default: ""
    },
    classText: {
      type: String,
      default: ""
    },
    colorBtn: {
      type: String,
      default: ""
    },
    depressedBtn: {
      type: Boolean,
      default: false
    },
    outlinedBtn: {
      type: Boolean,
      default: false
    },
    lightBtn: {
      type: Boolean,
      default: false
    },
    callbackBtn: {
      type: Function,
      default: () => {
      }
    }
  },
  methods: {}
}
</script>

<style scoped>


</style>
