<template>
  <v-container fluid>
    <Title :title="titlePage"></Title>
    <v-col class="white" >
      <BreadCrumb :historyPages="historyPages"></BreadCrumb>
      <v-container class="backgrey--bkgd border">
        <v-container>
          <v-row fluid>
            <TitleInput :titleInput="'Proceso a importar'"></TitleInput>
            <SelectInput
              :process-import="this.$store.state.processImport"
              :processes-import-names="this.$store.state.processesImportNames"
              :on-change-input="onChangeProcessImport"
              placeholder-input="Selecciona el proceso a importar"></SelectInput>

            <BtnDownloadXls
              :visible="stateHeadProcess() ? headImport !== '' : this.$store.state.processImport !== '' "
              title="Descarga archivo base"
              :nameFile="this.$store.state.processImport"
              :fields="{headers}"
              :data="this.$store.state.processesImportNames"
              :headImport="headImport"
              refComponent="download-base-file"></BtnDownloadXls>
          </v-row>
          <v-row fluid
                 v-if="stateHeadProcess()"
          >
            <TitleInput :titleInput="this.$store.state.selectedProcessImport.fields['Head']['LabelHead']"></TitleInput>
            <SelectInput
                :process-import="headImport"
                :processes-import-names="headImportNames"
                :on-change-input="onChangeHeadImport"
                :placeholder-input="`Selecciona el ${this.$store.state.selectedProcessImport.fields['Head']['LabelHead']} a importar`"></SelectInput>

          </v-row>
          <v-row fluid
                 v-else
          >
          </v-row>

          <v-row fluid>
            <TitleInput class="align-center d-flex" :titleInput="'Archivo a cargar'"></TitleInput>
            <BtnInputFile
              accept=".csv"
              id="input-file-csv"
              title="Añadir archivo"
              :on-data-load="this.onMountData"
              ></BtnInputFile>
            <TextInformative title="El archivo seleccionado debe estar formato de comas CSV"></TextInformative>
          </v-row>
        </v-container>
        <v-container v-if="message.enable">
          <PopupInformative
              :title="message.title"
              colorBkgd="headforms"
              :description="message.message"></PopupInformative>
        </v-container>
        <v-container v-if="loading">
          <ProgressBar
              :title="txtProgressBar"
              :percent="loadPercent"
              colorBar="titleslight"
              colorBkgd="white"
              :disabled="loading"
              heightBar="50"></ProgressBar>
        </v-container>
        <v-container>
          <v-card
              color="four"
              min-height="5em">
            <v-container class="d-flex justify-space-between align-center">
              <div></div>
              <div class="d-flex ma-5">
                <h3 class="text-center fs-1_3 font-weight-bold">{{
                    !modeImport ?
                        'Previsualiza el archivo' : 'Edita la informacion' }} a importar</h3>
                <v-btn
                    @click="changeModeImport"
                    class="ml-10"
                    text="">
                  <v-icon>{{  !modeImport ? 'fa-edit' : 'fa-eye' }}</v-icon>
                </v-btn>
              </div>
              <div>
                <v-btn
                    v-if="modeImport"
                    @click="addColumnImport"
                    text="">
                  <v-icon>{{  'fa-plus' }}</v-icon>
                </v-btn>
              </div>

            </v-container>
            <template v-if="modeImport === 0">
              <TablePreviewImport
                  :headersTable="headers"
                  :dataTable="datasetImport"></TablePreviewImport>
            </template>
            <template v-else>
              <TableEditImport
                  :schemaProcess="schemaProcess"
                  :headersTable="headers"
                  :dataTable="datasetImport"></TableEditImport>
            </template>
          </v-card>
        </v-container>
        <v-container>
          <v-row justify="center" class="ma-5">
            <Btn
              :callback-btn="goToBack"
              outlinedBtn
              colorBtn="buttondark"
              classBtn="px-md-16 py-md-6 text-capitalize"
              classText="fs-1_3 buttondark--text"
              elevationBtn="10"
              title="Cancelar"></Btn>
            <div class="mx-2"></div>
            <Btn :callback-btn="ApiImportingData"
                 :disabled="disabledImport"
              depressedBtn
              lightBtn
              :colorBtn="disabledImport ? 'buttondark' : 'ntitlesdark' "
              :classBtn="`px-md-8 py-md-6 text-capitalize ${disabledImport ? '' : 'cur-initial'}`"
              classText="fs-1_3 white--text"
              elevation="0"
              :title="txtTitleContinue"></Btn>


            <div @click="onDownloadFile"
                 v-if="enableDownloadErrors">
              <BtnDownloadLocalXls
                  :visible="enableDownloadErrors"
                  title="Descarga archivo con errores "
                  :nameFile="this.$store.state.processImport"
                  :fields="{headers}"
                  :dataErrors="setErrors"
                  refComponent="download-errors-file"></BtnDownloadLocalXls>
            </div>
          </v-row>

        </v-container>
      </v-container>
    </v-col>
  </v-container>
</template>
<script>

import BreadCrumb from "@/components/Importacion/BreadCrumb";
import Title from "@/components/Importacion/Title";
import TitleInput from "@/components/Importacion/TitleInput";
import SelectInput from "@/components/Importacion/SelectInput";
import BtnDownloadXls from "@/components/Importacion/BtnDownloadXls";
import BtnInputFile from "@/components/Importacion/BtnInputFile";
import TextInformative from "@/components/Importacion/TextInformative";
import PopupInformative from "@/components/Importacion/PopupInformative";
import ProgressBar from "@/components/Importacion/ProgressBar";
import TablePreviewImport from "@/components/Importacion/TablePreviewImport";
import Btn from "@/components/Importacion/Btn";
import axiosServices from "@/services/axiosServices";
import TableEditImport from "@/components/Importacion/TableEditImport";
import Ajv from "ajv";
import localize from 'ajv-i18n/localize/es';
import BtnDownloadLocalXls from "@/components/Importacion/BtnDownloadLocalXls";
import { mapGetters } from 'vuex';

export default {
  name:"Import",
  components: {
    Btn,
    TablePreviewImport,
    TableEditImport,
    ProgressBar,
    PopupInformative,
    TextInformative,
    BtnInputFile,
    BtnDownloadXls,
    BtnDownloadLocalXls,
    SelectInput,
    BreadCrumb,
    Title,
    TitleInput
  },
  methods: {
    onChangeProcessImport: async function (item) {
      await this.onCleanData();
      await this.unMountTable()
      await this.$store.dispatch('saveProcessImport',item);
      await this.$store.dispatch('saveTimestampImport', Math.trunc(new Date()/1000)); // timestamp Key to save logs until change the process newly
      await this.$store.dispatch('saveSelectedProcessImport',this.$store.state.processesImport.filter((process) => process.nameProcess === this.$store.state.processImport)[0]);
      await this.onMountTable();
    },
    onChangeHeadImport: async function (item) {
      this.headImport = item;
      this.modeImport = 0;
      this.oHeadImport = this.setItemsHead[item];
      await this.onMountTable(true);
    },
    shrinkKeyObj:async function(dataMasterOut, field){
      let aFormat = [];

      await dataMasterOut.aData.forEach((data) => {
        aFormat.push(data[field.split(',')[0]]);
      });
      return aFormat;
    },
    onMountTable: async function(onlyHead) {
      if(this.$store.state.processImport !== "") {
        let setHeadersPr = [];
        let setProcessImport = this.$store.state.selectedProcessImport;
        let fieldsProcess = setProcessImport.fields;
        this.headProcess = fieldsProcess['Head'];
        let setHeaders =  fieldsProcess.Headers || [];
        let widthHeader = setHeaders.length * 2 + 'em';
        this.message = setProcessImport.message;
        let counter;
        let setTypesEditor = {
          'string': 'string',
          'number': 'number',
          'boolean': 'select'
        };
        let setTypesSchema = {
          'string': 'string',
          'number': 'integer',
          'boolean': 'boolean'
        };
        const schemaProcess = {
          type: "object",
          properties: {}, //Dictionary with properties
          required: [], // Array with titles of required fields
          additionalProperties: true
        };
        let schemaOptional = {
          type: "object",
          properties: {}, //Dictionary with properties
          required: [], // Array with titles of required fields
          additionalProperties: false
        };

        // * Get Data Head
        if(this.headProcess && this.headImportNames!== undefined && this.headImportNames.length === 0) {
          const dataHead = await this.onGetDataMaster(null, this.headProcess['TBHeadProcess'], `${this.headProcess['FieldTBHeadProcess']},${this.headProcess['FieldDatabaseHeadProcess']}`);
          let aItems = {};
          dataHead.aData.forEach((itemHead) => {
            aItems[itemHead[this.headProcess['FieldTBHeadProcess']]] = itemHead;
          });
          this.setItemsHead = aItems;
          this.headImportNames = await this.shrinkKeyObj(dataHead, this.headProcess['FieldTBHeadProcess']);
        }

        for (counter in setHeaders) {
          let headers = setHeaders[counter];
          let valueField = headers['Label'];
          if (headers['IsRequired']) {
            valueField = valueField+' *';
            schemaProcess.required.push(valueField);
          }

          schemaOptional.properties[valueField] = {type: setTypesSchema[headers['Type']], nullable: headers['IsRequired']};
          schemaProcess.properties[valueField] = {type: setTypesSchema[headers['Type']], pattern: `^((?!(!Error:)).)${!headers['IsRequired'] ? '*': '+'}$`, nullable: !headers['IsRequired']};

          let headerToPush = {
            text: valueField,
            value: valueField,
            TypeEditor: setTypesEditor[headers['Type']],
            IsRequired: headers['IsRequired'],
            Type: setTypesSchema[headers['Type']],
            class: 'header-dt',
            align: 'center',
            width: widthHeader
          };
          if(headers['IsMaster'] ) {
            schemaProcess.properties[valueField].type = headers['Type'];
            if(!onlyHead) {
              headerToPush.options = await this.shrinkKeyObj(await this.onGetDataMaster(null, headers['TBMaster'], headers['FieldInTbMaster']), headers['FieldInTbMaster']);
            } else {
              headerToPush.options = this.headers[counter].options;
            }
          }
          if(headers['Head']) {
            schemaProcess.properties[valueField].type = headers['Type'];
            headerToPush.options = await this.shrinkKeyObj(await this.onGetDataMaster(this.oHeadImport[`${this.headProcess['FieldDatabaseHeadProcess']}`], headers['TBMaster'], headers['FieldInTbMaster']), headers['FieldInTbMaster']);
          }
          setHeadersPr.push(headerToPush);
        }
        // * Add Header State
        setHeadersPr.push({
          text: "Estado",
          value: 'Estado',
          Type: "string",
          TypeEditor: "string",
          IsRequired: false,
          class: 'header-dt ',
          align: 'center',
          width: widthHeader,
          fixed: true,
          sortable: false

        });
        this.headers=setHeadersPr;
        this.schemaProcess=schemaProcess;
        this.schemaOptional=schemaOptional;
      }
    },
    onGetProcesses: async function () {
      let processes = [];
      try {
        await axiosServices.getConfiguration('processes', false, {}).then((process) => {
          processes = process.aData;
          if(process['nStatusCode'] !== 200) {
            throw("error");
          }
        }).catch((er) => {
          throw Error(er);
        });
      } catch (e) {
        await this.$swal({
          icon: `error`,
          title: `Contactar con el administrador`,
          showConfirmButton: true,
          showCloseButton: true,
        });
        throw ("Error");
      }
      let setNames = ['', ];
      let setProcesses = ['', ];
      processes.forEach(p => {
        let process = {};
        process.fields = [];
        process.fields = JSON.parse(p['fields'])
        process.message = JSON.parse(p['message']);
        process.nameProcess = p['name_process'];
        process.proccessExecute = p['proccess_execute'];
        setProcesses.push(process);
        setNames.push(p['name_process']);
      });
      await this.$store.dispatch('saveProcessesImport', setProcesses);
      await this.$store.dispatch('saveProcessesImportNames', setNames);
    },
    onDownloadFile: async function () {
      this.enableDownloadErrors = !this.enableDownloadErrors;
    },
    ApiImportingData: async function() {
      if(this.stateHeadProcess()) {
        if(this.headImport === ''  ) {
          await this.$swal({
            icon: `error`,
            title: `Debes seleccionar una opción del campo ${this.headProcess['LabelHead']}`,
            showConfirmButton: true,
            showCloseButton: true,
          });
          throw Error('Required');
        }
      }
      this.loading  = true;
      this.loadPercent = 0;
      const ajv = new Ajv({coerceTypes:true, allErrors: true}); // options can be passed, e.g. {allErrors: true}
      const validate = ajv.compile(this.schemaProcess);
      let countErrors = 0;
      let totalItems = this.datasetImport.length;
      let inc = 100/totalItems;
      this.txtProgressBar = `Validando ${totalItems * Object.keys(this.datasetImport[0]).length} campos`;
      this.datasetImport.forEach((item) => {
        this.loadPercent += inc/2;
        const valid = validate(item);
        if (!valid) {
          item.Estado = 'Error';
          countErrors++;
        } else {
          this.loadPercent += inc/2;
        }
      });
      if (countErrors > 0) {
        await this.$swal({
          timer: 2000,
          icon: `error`,
          title: `Tienes errores en ${countErrors} columna${countErrors > 1 ? 's' : ''}`,
          showConfirmButton: true,
          showCloseButton: true,
        });
      } else {
        await this.$swal({
          title: `Validacion exitosa`,
          html: '<div><h3>Pulsa <strong style="color: rgb(48, 133, 214)">continuar</strong> para almacenar la informacion en el servidor</h3></div>',
          showConfirmButton: true,
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Continuar',
        }).then(function(result){
          if(result.dismiss === 'cancel'){
            throw new Error('Cancelar');
          }
        });
        this.loadPercent = 0;
        inc = 100 / totalItems;
        this.txtProgressBar = `Enviando ${totalItems * Object.keys(this.datasetImport[0]).length} campos`;
        let setErrors = [];
        let params = `process=${this.$store.state.processImport}`;
        if(this.headImport !== "")  {
          params = params+`&headValue=`+this.headImport;
        }
        for(let j = 0; j < totalItems; j++) {
          await this.sleep(500);
          this.loadPercent += inc;
          let bodyImportPetition = this.datasetImport[j];
          const aDataImport = await axiosServices.postConfiguration(
              '/processes/import',
              {'fields':bodyImportPetition, 'timestampImport': this.$store.state.timestampImport},
              false,
              params
              );
          this.datasetImport[j]['aHistory'] = aDataImport['aHistory'];
          this.datasetImport[j]['aData'] = aDataImport['aData'];
          if (aDataImport['nStatusCode'] === 200) {
            this.datasetImport[j]['Estado'] = 'Completado';
          } else {
            setErrors.push(this.datasetImport[j]);
            this.datasetImport[j]['Estado'] = 'Error';
          }
        }
        if(setErrors.length === 0) {
          await this.onCleanData()
          await this.unMountTable();
          await this.$swal({
            icon: `success`,
            title: `Importación finalizada con Exito`,
            showConfirmButton: true,
            confirmButtonText: 'Continuar',
            showCloseButton: true,
          });
        } else {
          this.enableDownloadErrors = true;
          this.setErrors = setErrors;
          await this.$swal({
            icon: `error`,
            title: `Error de importación`,
            showConfirmButton: true,
            confirmButtonText: 'Revisar',
            showCloseButton: true,
          });
        }
      }
    },
    onGetDataMaster: async function (database, table, field) {
      let dataMaster = [];
      let error = false;
      let setParams = `table_master=${table}&field_master=${field}`;
      if(typeof database == "string") {
        setParams = setParams+`&db_master=${database}`;
      }
      try {
        await axiosServices.getConfiguration('processes/master', false, setParams).then((dataMasterOut) => {
          dataMaster = dataMasterOut;
        });
      } catch (e) {
        error = e;
        throw Error(error);
      }
      return dataMaster;
    },
    unMountTable: async function() {
      await this.$store.dispatch('saveProcessImport',"");
      await this.$store.dispatch('saveSelectedProcessImport',[]);
    },
    onMountData: async function(data) {
      this.loading  = true;

      this.loadPercent = 0;
      const ajv = new Ajv({coerceTypes:false, allErrors: true}); // options can be passed, e.g. {allErrors: true}

      const validateOptional = ajv.compile(this.schemaOptional);
      let isInsertedLast = false;
      if(data.data[data.data.length-1] !== undefined) {
        isInsertedLast = data.data[data.data.length-1].Estado === "Insertado";
      }

      if(isInsertedLast || data.data.length > 0){
        // same file
        let setDataPreview = [];
        let countErrors = 0 ;
        let totalItems = data.data.length;
        let inc = 100/totalItems;
        this.txtProgressBar = `Importando ${totalItems} registros`;
        data.data.forEach( (itemData, count)  => {
          const validOptional = validateOptional(itemData);
          if(validOptional){
            const isInserted = itemData.Estado === "Insertado";
            const validOptional = validateOptional(itemData);
            if(validOptional || isInserted) {
              let setData = {"Estado": '', 'idExpand': count};  // Error, Importado, Insertado
              this.loadPercent += inc;
              this.$store.state.selectedProcessImport.fields.Headers.forEach((field) => {
                let labelField=field['Label'];
                let valueField;
                if (field.IsRequired === false) {
                  valueField = itemData[labelField]
                  setData[labelField] = valueField;
                } else {
                  valueField = itemData[labelField + ' *']
                  setData[labelField + ' *'] = valueField;
                  if (field.Type === typeof valueField && valueField != null) {
                    setData["Estado"] = isInserted ? 'Insertado' : 'Importado';
                  } else {
                    const sAltFieldError = `${this.$store.state.errorMessagesImport} ${valueField}`;
                    countErrors++;
                    let key = field.IsRequired ? labelField + ' *' : labelField;
                    setData[key] = sAltFieldError;
                    setData["Estado"] = 'Error';
                  }
                }
              });
              setDataPreview.push(setData);
            } else if(!isInserted)  {
              this.$swal({
                icon:`success`,
                title:`Importación ${isInserted} con error en ${count}`,
                showConfirmButton: true,
                showCloseButton: true,
              });
            }
          } else {
            localize(validateOptional.errors);

            let setMsgError = [];
            let Mensaje = ''
            validateOptional.errors.forEach((itmError) => {
              Mensaje = itmError.message.indexOf('integer') >= 0 ? 'Este campo solo permite valores numericos': 
                        itmError.message.indexOf('string') >= 0 ? 'Este campo solo permite valores alfanumericos' : 
                        itmError.message.indexOf('boolean') >= 0 ? 'Este campo solo permite valores Si - NO': itmError.message

              setMsgError.push(`<li>${itmError.instancePath.replace(/^./, "")} -> ${Mensaje}</li>`);
            });
            this.$swal({
              icon:`error`,
              title:`El archivo cargado del proceso ${this.getProcessImport} presenta errores` ,
              html: `<ul><b>Fila ${count+1}</b>${setMsgError}</ul>`,
              showConfirmButton: true,
              showCloseButton: true,
              
            });
            throw("Eroor");
          }
        });
        if(countErrors === 0 ) {
          await this.$swal({
            icon: `success`,
            title: `Importación exitosa`,
            showConfirmButton: true,
            showCloseButton: true,

          });
        } else if(!isInsertedLast) {
          await this.$swal({
            timer: 3000,
            icon: `error`,
            title: `Importación con ${countErrors} error${countErrors > 1 ? 'es' : ''}`,
            showConfirmButton: true,
            showCloseButton: true,
          });
        }
      this.loadPercent = 0;
      this.disabledImport = false;
      this.datasetImport = setDataPreview;
      }

    },
    onCleanData: async function () {
      this.headers = [];
      this.datasetImport = [];
      this.headImport= "";
      this.oHeadImport= {};
      this.setItemsHead= {};
      this.headImportNames = [];
      this.modeImport = 0;
      this.loadPercent = 0;
      this.loading  = false;
    },
    addColumnImport: async function () {
      this.disabledImport = false;
      let data = this.datasetImport.push({'Estado': 'Insertado', 'idExpand': this.datasetImport.length});
      await this.onMountData({data: data});
    },
    changeModeImport: async function () {
      this.modeImport = this.modeImport === 0 ? 1 : 0;
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    stateHeadProcess() {
      this.bHeadProcess = this.$store.state.selectedProcessImport && this.$store.state.selectedProcessImport.fields && this.$store.state.selectedProcessImport.fields['Head'];
      return this.bHeadProcess;
    },
    goToBack() {
      window.history.back();
    }

  },
  data() {
    this.onMountTable();

    return {
      titlePage: "Importa tus archivos",
      loadPercent:0,
      sTitle: 'Generando informe...',
      setProccess: this.$store.state.processesImportNames,
      modeImport: 0,
      historyPages: [
        {
          text: 'Inicio',
          disabled: false,
          href: '/',
        },
        {
          text: 'Configuración',
          disabled: false,
          href: 'DashBoard',
        },
        {
          text: 'Importación',
          disabled: true,
          href: 'Import',
        },
      ],
      loading: false,
      headers: [],
      schemaProcess: {},
      schemaOptional: {},
      datasetImport: [],
      disabledImport: true,
      message: {},
      headProcess: {},
      txtProgressBar: "Cargando",
      enableDownloadErrors: false,
      setErrors: [],
      txtTitleContinue: 'Validar información',
      headImport: "",
      oHeadImport: {},
      setItemsHead: {},
      headImportNames: [],
      aHeadImportMap: [],
      bHeadProcess: false
    }
  },
  mounted () {
    this.onMountTable();
    this.onGetProcesses();
  },
  computed: {
    ...mapGetters(['getProcessImport'])
  },
}
</script>

<style lang="scss">
.v-chip {
  width: 100%;
  padding: 0 20%;

  .v-chip__content {
    text-align: center;
    margin: auto;
  }
}

.header-dt span {
  color: #012362;
  font-size: 1.2em;
  font-weight: 900;
}
.mytable {
  table {
    tr {
      td {
        padding: 1.2px 0 !important;
        color: rgb(44,42,42);
        font-size: .6em !important;
      }
    }
  }
}
.v-data-table td {
  font-size: 2em;
}

a {
  text-decoration: underline;
}
</style>
